<div >
  <h2 mat-dialog-title class="d-flex mb-0"><span>Select a User to Impersonate</span>
    <button class="close-icon" mat-dialog-close>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.29279 8.29259C8.48031 8.10512 8.73462 7.99981 8.99979 7.99981C9.26495 7.99981 9.51926 8.10512 9.70679 8.29259L15.9998 14.5856L22.2928 8.29259C22.385 8.19708 22.4954 8.1209 22.6174 8.06849C22.7394 8.01608 22.8706 7.9885 23.0034 7.98734C23.1362 7.98619 23.2678 8.01149 23.3907 8.06177C23.5136 8.11205 23.6253 8.18631 23.7192 8.2802C23.8131 8.37409 23.8873 8.48574 23.9376 8.60864C23.9879 8.73154 24.0132 8.86321 24.012 8.99599C24.0109 9.12877 23.9833 9.25999 23.9309 9.382C23.8785 9.504 23.8023 9.61435 23.7068 9.70659L17.4138 15.9996L23.7068 22.2926C23.8889 22.4812 23.9897 22.7338 23.9875 22.996C23.9852 23.2582 23.88 23.509 23.6946 23.6944C23.5092 23.8798 23.2584 23.985 22.9962 23.9873C22.734 23.9895 22.4814 23.8888 22.2928 23.7066L15.9998 17.4136L9.70679 23.7066C9.51818 23.8888 9.26558 23.9895 9.00339 23.9873C8.74119 23.985 8.49038 23.8798 8.30497 23.6944C8.11956 23.509 8.01439 23.2582 8.01211 22.996C8.00983 22.7338 8.11063 22.4812 8.29279 22.2926L14.5858 15.9996L8.29279 9.70659C8.10532 9.51907 8 9.26476 8 8.99959C8 8.73443 8.10532 8.48012 8.29279 8.29259Z" fill="currentColor"/>
      </svg>        
    </button>
  </h2>  
</div>
<form class="p-3" [formGroup]="impersonationForm" (ngSubmit)="onSave()" >
  <p>Choose the type of user you need to act on behalf of to provide support or complete actions they are unable to perform.</p>
  <label class="form-label">Select User Role to Impersonate<span class="text-danger">*</span></label>
  <!-- Role Selection -->
   <div class="row">
    <mat-radio-group class="d-flex column-gap-3 flex-wrap px-0" formControlName="role" (change)="onRoleChange()">
      <mat-radio-button value="jobSeeker">Job Seeker</mat-radio-button>
      <mat-radio-button value="employer">Employer</mat-radio-button>
      <mat-radio-button value="communityPartner">Community Partner</mat-radio-button>
    </mat-radio-group>
   </div>
  <ng-container *ngIf="impersonationForm.get('role')?.value === 'jobSeeker'">
    <div class="mt-3 d-flex flex-column">
      <label class="form-label">Search and select the Job Seeker<span class="text-danger">*</span></label>
      <mat-form-field appearance="fill" >
        <mat-label *ngIf="!userControl.value">Select User</mat-label>
        <input type="text" matInput [formControl]="userControl" (input)="onUserSearch($event)" [matAutocomplete]="auto" formControlName="selectedUser">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelection($event.option.value)">
          <mat-option *ngFor="let user of filteredUsers" [value]="user.email">
            {{ user.firstName }} {{ user.lastName }} {{ user?.email ? '(' + user.email + ')' : '' }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="impersonationForm.get('role')?.value === 'employer'">
    <div class="mt-3 d-flex flex-column">
      <label class="form-label">Search and select the Employer<span class="text-danger">*</span></label>
      <mat-form-field appearance="fill">
        <mat-label *ngIf="!userControl.value">Select Employer</mat-label>
        <input type="text" matInput [formControl]="userControl" (input)="onEmployerSearch($event)" [matAutocomplete]="auto" formControlName="selectedUser">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelection($event.option.value)">
          <mat-option *ngFor="let employer of filteredEmployer" [value]="employer.email">
            {{ employer.firstName }} {{ employer.lastName }} {{ employer?.email ? '(' + employer.email + ')' : '' }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="impersonationForm.get('role')?.value === 'communityPartner'">
    <div class="mt-3 d-flex flex-column">
      <label class="form-label">Search and select the Employer<span class="text-danger">*</span></label>
      <mat-form-field appearance="fill">
        <mat-label *ngIf="!userControl.value">Select Community Partner</mat-label>
        <input type="text" matInput [formControl]="userControl" (input)="onCommunityPartnerSearch($event)" [matAutocomplete]="auto" formControlName="selectedUser">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelection($event.option.value)">
          <mat-option *ngFor="let cp of filteredCP" [value]="cp.email">
            {{ cp.firstName }} {{ cp.lastName }} {{ cp?.email ? '(' + cp.email + ')' : '' }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </ng-container>
  <!-- UX for Job Seeker -->
  <!-- <ng-container *ngIf="impersonationForm.get('role')?.value === 'jobSeeker'">
    <p>There are too many job seekers to show in a dropdown. Please use an alternative UX.</p> -->
    <!-- Implement an alternative user lookup method here -->
  <!-- </ng-container> -->
  <div mat-dialog-actions>
    <button class="btn btn-outline-grey" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary" type="submit">Impersonate User</button>
  </div>
</form>