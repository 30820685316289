import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { applyStyles } from '@popperjs/core';
import { SignUpRoles } from '@portal/configs';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import { AppConstants } from 'src/app/appconstants';
import { AuthService } from 'src/app/services/authguard/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { EmployerProfileDto, UserProfileDto } from 'src/app/utils/types';
@Component({
  selector: 'app-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrl: './impersonation.component.css',
})
export class ImpersonationComponent implements OnInit, OnDestroy {
  userControl = new FormControl();
  impersonationForm!: FormGroup;
  filteredUsers!: UserProfileDto[];
  filteredEmployer!: EmployerProfileDto[];
  filteredCP!: EmployerProfileDto[];
  subscription: Subscription | null = null;
  selectedValueForImpersonation: string = '';
  defaultUsers: UserProfileDto[] = [];
  userData$ = new Observable<any>();
  userSearchQuery$ = new BehaviorSubject<string>('');
  employerData$ = new Observable<any>();
  EmployerSearchQuery$ = new BehaviorSubject<string>('');
  cpSearchQuery$ = new BehaviorSubject<string>('');
  cpData$ = new Observable<any>();
  @ViewChild('auto') autoComplete!: ElementRef;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ImpersonationComponent>,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private readonly auth: AuthService,
    private router: Router
  ) {}
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
  ngOnInit(): void {
    this.impersonationForm = this.fb.group({
      role: ['', Validators.required],
      selectedUser: ['', Validators.required],
    });
    this.impersonationForm.get('role')?.valueChanges.subscribe(() => {
      this.impersonationForm.get('selectedUser')?.reset();
      this.selectedValueForImpersonation = '';
      this.filteredUsers = this.defaultUsers; // Reset filtered users
    });
    this.updateUserList();
    this.updateEmployerList();
    this.updateCPList();
  }

  onRoleChange(): void {
    // Reset the selectedUser field when the role changes
    this.userControl.reset();
  }
  
  private updateUserList() {
    this.userData$ = this.userSearchQuery$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => {
        if (searchTerm.trim().length < 1) return of({ data: [] });
        return this.filterUsers(searchTerm);
      })
    );
    this.userData$.subscribe((users) => {
      this.filteredUsers = users.data?.length > 0 ? users.data : [];
    });
  }
  private updateEmployerList() {
    this.employerData$ = this.EmployerSearchQuery$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => {
        if (searchTerm.trim().length < 1) return of({ data: [] });
        return this.filterUsers(searchTerm);
      })
    );
    this.employerData$.subscribe((employers) => {
      this.filteredEmployer = employers.data?.length > 0 ? employers.data : [];
    });
  }

  private updateCPList() {
    this.cpData$ = this.cpSearchQuery$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => {
        if (searchTerm.trim().length < 1) return of({ data: [] });
        return this.filterUsers(searchTerm);
      })
    );
    this.cpData$.subscribe((users) => {
      this.filteredCP = users.data?.length > 0 ? users.data : [];
    });
  }

  onSelection(selectedValue: string) {
    this.selectedValueForImpersonation = selectedValue;
  }
  filterUsers(searchTerm: string) {
    const role = this.impersonationForm.get('role')?.value;
    if (role === 'jobSeeker') {
      return this.profileService.filteredByName(searchTerm);
    } else if (role === 'employer') {
      return this.profileService.filteredEmployerByName(searchTerm);
    } else if (role === 'communityPartner') {
      return this.profileService.filteredCPByName(searchTerm); }
    else {
      return new Observable();
    }
  }
  onUserSearch(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.userSearchQuery$.next(searchTerm);
  }

  onEmployerSearch(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.EmployerSearchQuery$.next(searchTerm);
  }

  onCommunityPartnerSearch(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.cpSearchQuery$.next(searchTerm);
  }



  filterByRole(user: any, role: string) {
    if (role === 'employer') {
      return user.employerId !== undefined;
    }
    if (role === 'communityPartner') {
      return user.partnerId !== undefined;
    }
    return false;
  }
  onSave(): void {
    const selectedUser = this.selectedValueForImpersonation;
    if (!selectedUser) {
      alert('no value selected');
      return;
    }
    this.loadUserProfile(selectedUser);
    this.dialogRef.close(selectedUser);
  }
  private async loadUserProfile(email: string): Promise<void> {
    let origuser = this.profileService.getUserClaims();
    localStorage.setItem(AppConstants.ORIGINAL_USER, JSON.stringify(origuser));
    this.auth.getUerProfile(email).subscribe((response: any) => {
      const userData = response.data;
      var userObj = null;
      if(userData.role === AppConstants.COMMPARTNER)
      {
        userObj = {
          userId: userData.cboProfile.id,
          firstName: userData.cboProfile.repFirstName,
          lastName: userData.cboProfile.repLastName,
          employer: userData.employer?.id || null,
          role: userData.role,
          email: email,
        };
      }
      else{
          userObj = {
            userId: userData.userProfile.id,
            firstName: userData.userProfile.firstName,
            lastName: userData.userProfile.lastName,
            employer: userData.employer?.id || null,
            profileCompletionStatus: true,
            role: userData.role,
            email: email,
          };
      }
      localStorage.setItem(AppConstants.USER, JSON.stringify(userObj));
      if (response.data.employer != undefined) {
        const employer = {
          employerId: response.data.employer.id,
          employerName: response.data.employer.name,
          einNumber: response.data.employer.einNumber,
        };
        localStorage.setItem(
          AppConstants.EMPLOYERDATA,
          JSON.stringify(employer)
        );
      }
      this.auth.loggedIn.next(true);

      if(userData.role === AppConstants.COMMPARTNER)        
        this.checkcboUserStatus(userData);
      else
        this.checkUserStatus(userData);

      const isJobSeeker = userData.role === AppConstants.JOBSEEKER;
      const isEmployer = userData.role === AppConstants.EMPLOYER;
      if (isJobSeeker) {
        if (userData.userProfile.firstName) {
          this.router.navigate([AppConstants.JOBSEEKER_DASHBOARD]);
        } else {
          this.gotoProfile(userObj);
        }
      } else if (isEmployer) {
        if (userData.employer?.isApproved) {
          this.router.navigate([AppConstants.EMPLOYER_DASHBOARD]);
        } else if (userData.employer?.name) {
          this.router.navigate([AppConstants.EMPLOYER_PROFILE_URL]);
        } else {
          this.gotoProfile(userObj);
        }
      }
      else if(userData.role === AppConstants.COMMPARTNER)
      {
        this.gotoProfile(userObj);
      }
    });
  }

  checkUserStatus(user: any): void {
    if (!user.userProfile.isActive) {
      this.profileService.enableDisableUser(user.userProfile.id).subscribe({
        next: (resp) => {
          if (resp.data) {
            this.toastr.success(AppConstants.REACTIVATED, '', {
              timeOut: 60000,
              closeButton: true,
            });
          }
        },
        error: (err) => {
          console.error('Error enabling user', err);
        },
      });
    }
  }

  checkcboUserStatus(user: any): void {
    if (!user.cboProfile.isActive) {
      this.profileService.enableDisableUser(user.cboProfile.id).subscribe({
        next: (resp) => {
          if (resp.data) {
            this.toastr.success(AppConstants.REACTIVATED, '', {
              timeOut: 60000,
              closeButton: true,
            });
          }
        },
        error: (err) => {
          console.error('Error enabling user', err);
        },
      });
    }
  }


  private gotoProfile(obj: any): void {
    if (obj.role === SignUpRoles.Employer) {
      this.router.navigate([AppConstants.EMPLOYER_EDIT_PROFILE]);
    } else if (obj.role === SignUpRoles.JobSeeker) {
      this.router.navigate([AppConstants.JOBSEEKER_EDIT_PROFILE]);
    }  else if (obj.role === AppConstants.COMMPARTNER) {
      this.router.navigate([AppConstants.CBO_PROFILE]);
    }else {
      this.router.navigate(['/']);
    }
  }
}
